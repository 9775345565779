<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.keywords"
        :placeholder="t('table.keywords')"
        style="width: 210px"
        class="filter-item"
        @keyup.enter="handleFilter"
      />
      <el-button
        v-waves
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
      >
        {{ t("table.search") }}
      </el-button>
    </div>
    <el-table
      :key="tableKey"
      v-loading="listLoading"
      :data="list"
      fit
      highlight-current-row
      empty-text="暂无数据"
      style="width:100%"
    >
      <el-table-column
        :label="t('table.id')"
        prop="id"
        align="center"
        width="80px"
      >
        <template #default="{row}">
          <span>{{ (listQuery.index-1)*listQuery.size+list.indexOf(row) + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="t('table.userName')"
      >
        <template #default="{row}">
          <span>{{ row.nickName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="t('table.createTime')"
        class-name="status-col"
        width="160px"
      >
        <template #default="{row}">
          {{ formatTime(row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column
        :label="t('table.actions')"
        align="center"
        width="100px"
        class-name="fixed-width"
      >
        <template #default="{row}">
          <el-switch
            v-model="row.status"
            active-text="启用"
            @change="handleUpdate(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      v-show="total > 0"
      v-model:page="listQuery.page"
      v-model:limit="listQuery.limit"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[10, 20, 50, 100]"
      layout="total, sizes, prev, pager, next, jumper"
    />
  </div>
</template>

<script lang='ts'>
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  onMounted
} from 'vue'
import { ElForm, ElMessage } from 'element-plus'
import moment from 'moment-timezone'
import {
  getUsers, update
} from '@/apis/user'
import { UserInfoModel } from '@/model/userModel'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    const dataForm = ref(ElForm)
    const dataMap = reactive({
      tableKey: 0,
      list: Array<UserInfoModel>(),
      total: 0,
      listLoading: true,
      listQuery: {
        index: 1,
        size: 10,
        keywords: ''
      },
      pageviewsData: [],
      downloadLoading: false,
      formatTime(d: any) {
        moment.tz.setDefault('Asia/Shanghai')
        return d ? moment(d).format('YYYY-MM-DD HH:mm:ss') : ''
      },
      handleCurrentChange(page?: any) {
        dataMap.getList(page)
      },
      handleSizeChange(val: any) {
        dataMap.getList(null, val)
      },
      async handleUpdate(row: any) {
        await update(row.id, row).catch((e) => { console.log(e) })
        ElMessage({
          message: '操作成功',
          type: 'success'
        })
      },
      async getList(index?: any, size?: any) {
        if (index) {
          dataMap.listQuery.index = index
        }
        if (size) {
          dataMap.listQuery.size = size
        }
        dataMap.listLoading = true
        const data = await getUsers(dataMap.listQuery)
        dataMap.list = data?.data.items ?? []
        dataMap.total = data?.data.total ?? 0
        setTimeout(() => {
          dataMap.listLoading = false
        }, 0.5 * 1000)
      },
      handleFilter() {
        dataMap.listQuery.index = 1
        dataMap.getList()
      }
    })
    onMounted(() => {
      console.log(typeof ElForm)
      dataMap.getList(null, 10)
    })
    return { t, ...toRefs(dataMap), dataForm }
  }
})
</script>
